const IconPaiement10x = props => (
	<svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M35 12.754a9.674 9.674 0 0 0-13.888-8.708 9.576 9.576 0 0 0-4.508 4.508H1.736c-.46.013-.9.198-1.232.518A1.736 1.736 0 0 0 0 10.29v15.862a1.722 1.722 0 0 0 .504 1.218c.332.32.771.505 1.232.518h25.662a1.82 1.82 0 0 0 1.232-.518 1.723 1.723 0 0 0 .504-1.218v-4.494a9.576 9.576 0 0 0 4.27-3.57A9.8 9.8 0 0 0 35 12.754Zm-19.082-2.282a10.639 10.639 0 0 0-.28 2.31v.63H1.904v-2.94h14.014ZM27.23 25.998H1.904v-9.03h14.7a9.59 9.59 0 0 0 3.57 4.004 9.59 9.59 0 0 0 7 1.288l.056 3.738Zm-1.904-5.11a8.106 8.106 0 1 1 .004-16.211 8.106 8.106 0 0 1-.004 16.211Zm-7-9.198 1.4-.364v2.8h-1.4v1.078H22.4v-1.078h-1.316V9.758H21l-2.674.77v1.162Zm4.41.84c0 1.75.896 2.8 2.17 2.8s2.17-1.036 2.17-2.8c0-1.764-.882-2.8-2.17-2.8-1.288 0-2.156 1.064-2.156 2.8h-.014Zm1.4 0c0-1.078.294-1.61.826-1.61s.826.532.826 1.61-.294 1.666-.826 1.666-.868-.588-.868-1.666h.042ZM30.8 9.884l-.728 1.218-.196.378-.21-.336-.728-1.232h-1.596l1.582 2.45-1.848 2.842H28.7l.924-1.54.196-.35.182.35.896 1.54h1.638L30.8 12.446l1.638-2.562H30.8Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPaiement10x;
