const IconHelp = props => (
	<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 21 20">
		<g clipPath="url(#a)">
			<path
				d="M10 .5C4.48.5 0 4.98 0 10.5s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm8.24 7.12-3.12.88c-.56-1.36-1.6-2.4-2.88-2.96l.8-3.2c2.4.88 4.32 2.8 5.2 5.28ZM10 14.66c-2.24 0-4.16-1.84-4.16-4.16 0-2.24 1.84-4.16 4.16-4.16 2.32 0 4.16 1.84 4.16 4.16 0 2.24-1.76 4.16-4.16 4.16ZM7.04 2.26l.88 3.12c-1.36.56-2.4 1.6-2.96 2.88l-3.2-.8c.88-2.4 2.88-4.32 5.28-5.2ZM1.76 13.3l3.12-.88c.56 1.36 1.6 2.48 2.96 3.12l-.8 3.2c-2.48-.96-4.48-2.96-5.28-5.44Zm11.12 5.44L12 15.62c1.28-.56 2.4-1.52 2.96-2.8l3.2.8c-.88 2.32-2.8 4.24-5.28 5.12Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="currentColor" transform="translate(0 .5)" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default IconHelp;
