const IconCross = () => (
	<svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.06115 5.35404L10.7076 0.707595L10.0005 0.000488281L5.35404 4.64693L0.707595 0.000488281L0.000488281 0.707595L4.64693 5.35404L0.000488281 10.0005L0.707595 10.7076L5.35404 6.06115L10.0005 10.7076L10.7076 10.0005L6.06115 5.35404Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconCross;
