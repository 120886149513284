const IconTickbox = props => (
	<svg viewBox="0 0 23.1 23.3" {...props}>
		<defs>
			<style>
				{".cls-1{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:1.5px;}"}
			</style>
		</defs>
		<path
			className="cls-1"
			d="M18.9,1.65H4.2a2.69,2.69,0,0,0-2.7,2.7v14.7a2.69,2.69,0,0,0,2.7,2.7H18.9a2.69,2.69,0,0,0,2.7-2.7V4.35A2.69,2.69,0,0,0,18.9,1.65Z"
		/>
	</svg>
);

export default IconTickbox;
