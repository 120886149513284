const IconPaiement2x = props => (
	<svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M35 12.758a9.68 9.68 0 0 0-18.402-4.197H1.734A1.74 1.74 0 0 0 0 10.295v15.853a1.74 1.74 0 0 0 1.734 1.734h25.67a1.74 1.74 0 0 0 1.734-1.734v-4.492A9.67 9.67 0 0 0 35 12.758Zm-19.078-2.29a10.12 10.12 0 0 0-.278 2.307c0 .226.018.434.018.642H1.908V10.47h14.014ZM27.23 25.992H1.908v-9.018h14.69a9.687 9.687 0 0 0 10.632 5.29v3.728Zm-1.908-5.098a8.126 8.126 0 1 1-.021-16.252 8.126 8.126 0 0 1 .021 16.252Z"
			fill="currentColor"
		/>
		<path
			d="M22.495 14.06c.73-.816 1.492-1.545 1.492-2.741a1.972 1.972 0 0 0-2.116-1.944c-.989 0-1.7.347-2.394 1.405l.972.763a1.491 1.491 0 0 1 1.249-.972.734.734 0 0 1 .815.798c0 .867-.936 1.717-2.584 3.66l-.433.52v.193h4.735V14.51h-2.134l.398-.45ZM30.872 9.515h-1.665l-.92 1.457-.277.469-.26-.451-.921-1.475h-1.683l1.908 2.879-2.218 3.347h1.718l1.145-1.838.26-.45.242.433 1.128 1.855h1.717l-2.15-3.226 1.976-3Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPaiement2x;
