const IconPhone = props => (
	<svg viewBox="3046.5 46.5 45.5 53" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			strokeMiterlimit={10}
			strokeWidth={3}
			d="M13.989 38.141a41.772 41.772 0 0 0 10.958 9.626 43.811 43.811 0 0 0 12.874 5.579 3.546 3.546 0 0 0 1.682.083 3.828 3.828 0 0 0 1.349-.683l4-3.181a3.228 3.228 0 0 0 .533-4.53L39.1 37.109a3.228 3.228 0 0 0-4.53-.533l-4.18 3.314s-2.865-1.033-6.828-5.846a89.484 89.484 0 0 1-1.732-2.132l-.167-.2.017-.017-.35-.433-.35-.45-.017.017-.167-.2c-.4-.516-.916-1.182-1.682-2.182-3.78-4.946-4.13-7.977-4.13-7.977l4.164-3.3a3.228 3.228 0 0 0 .533-4.53l-6.3-7.911A3.235 3.235 0 0 0 8.843 4.2l-4 3.181A3.754 3.754 0 0 0 3.9 8.547a3.289 3.289 0 0 0-.3 1.653A43.23 43.23 0 0 0 6.1 24a42.315 42.315 0 0 0 6.878 12.84c.167.2.333.416.516.633.162.235.329.469.495.668z"
			data-name="Trac\xE9 545"
			transform="translate(3044.4 44.5)"
		/>
	</svg>
);

export default IconPhone;
