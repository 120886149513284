const IconPaiement4x = props => (
	<svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M22.218 15.741v-1.197h-3.07v-.139l2.116-5.098h1.528l-1.734 4.076h1.162l.086-1.44h1.353v1.44h.815v1.162h-.817v1.196h-1.44ZM31.011 9.376 29 12.446l2.187 3.296h-1.752l-1.145-1.89-.243-.452-.26.451-1.183 1.89h-1.75l2.273-3.416-1.944-2.93h1.701l.954 1.508.26.45.277-.467.937-1.492h1.7v-.018Z"
			fill="currentColor"
		/>
		<path
			d="M35 12.758a9.68 9.68 0 0 0-18.402-4.197H1.734A1.74 1.74 0 0 0 0 10.295v15.853a1.74 1.74 0 0 0 1.734 1.734h25.67a1.74 1.74 0 0 0 1.734-1.734v-4.492A9.67 9.67 0 0 0 35 12.758Zm-19.078-2.29a10.12 10.12 0 0 0-.278 2.307c0 .226.018.434.018.642H1.908V10.47h14.014ZM27.23 25.992H1.908v-9.018h14.69a9.687 9.687 0 0 0 10.632 5.29v3.728Zm-1.908-5.098a8.126 8.126 0 1 1-.021-16.252 8.126 8.126 0 0 1 .021 16.252Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPaiement4x;
